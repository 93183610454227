@import "/src/css/_loader.scss";

:global(.spin-loader) {
  @include spinLoader;
}

:global(.spin-loader-blur) {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
  border-radius: 16px;

  &:global(.l-absolute) {
    position: absolute;
  }

  &:global(.l-fixed) {
    position: fixed;
  }

  &:global(.form) {
    border-radius: 16px;
  }

  &:global(.l-static) {
    position: static;
    transform: unset;
  }
}

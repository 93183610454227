.input__wrapper {
  margin-bottom: 16px;

  &-inner {
    max-width: 344px;

    position: relative;

    .icon__right {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      width: 20px;
      height: 20px;

      > img {
        max-width: 20px;
        max-height: 20px;
      }

      &:hover {
        > img {
          display: none;
        }
      }

      &-active {
        &:hover {
          background-image: url('../../../images/ico_eye-hover.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 20px;
          width: 20px;
          height: 20px;
        }

      }

      &.icon__show {
        &:hover {
          background-image: url('../../../images/ico_eye-off-hover.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 20px;
          width: 20px;
          height: 20px;
        }
      }
    }

  }

  .input__global {
    align-items: center;
    padding: 12px 16px;
    width: 100%;
    max-width: 344px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(25, 25, 38, 0.12);
    border-radius: 12px;
    cursor: pointer;
    outline: none;

    &.password {
      padding-right: 44px !important;
    }


    &::placeholder {
      $fontFamilySatoshi: 'Satoshi';
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #5F5F62;
    }

    //
    //&:hover {
    //  border: 2px solid rgb(15, 18, 20);
    //  padding: 11px 15px;
    //}
    //
    //&:focus {
    //  border: 1px solid rgb(15, 18, 20);
    //  box-shadow: 0 0 0 2.8px rgba(20, 20, 43, 0.12);
    //  padding: 12px 16px;
    //
    //}
    //
    //&:active {
    //  border: 1px solid rgba(25, 25, 38, 0.12);
    //  padding: 12px 16px;
    //}

    &.error {
      border: 1px solid rgb(238, 80, 80);

      //&:hover {
      //  padding: 12px 16px;
      //}

      //&:focus {
      //  border: 1px solid rgb(238, 80, 80);
      //  box-shadow: 0 0 0 2.8px #facece;
      //  padding: 12px 16px;
      //
      //}
    }
  }
}



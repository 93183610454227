@mixin spinLoader() {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 1;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid rgba(153, 158, 173, 1);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgba(153, 158, 173, 1) transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }


  &:global(.l-absolute) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:global(.l-fixed) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:global(.l-large) {
    width: 40px;
    height: 40px;

    div {
      width: 32px;
      height: 32px;
      margin: 4px;
    }
  }

  &:global(.l-medium) {
    width: 30px;
    height: 30px;

    div {
      width: 24px;
      height: 24px;
      margin: 3px;
      border: 3px solid rgba(153, 158, 173, 1);
    }
  }

  &:global(.l-small) {
    width: 20px;
    height: 20px;

    div {
      width: 16px;
      height: 16px;
      margin: 2px;
      border: 2px solid rgba(153, 158, 173, 1);
    }
  }

  &:global(.l-white) {
    div {
      border-color: rgba(255, 255, 255, 1) transparent transparent transparent;
    }
  }

  &:global(.l-red) {
    div {
      border-color: rgba(221, 44, 129, 1) transparent transparent transparent;
    }
  }

  &:global(.l-blue) {
    div {
      border-color: rgba(27, 67, 239, 1) transparent transparent transparent;
    }
  }

  &:global(.l-dark) {
    div {
      border-color: rgba(153, 158, 173, 1) transparent transparent transparent;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@import "src/css/typography";

.select {
  position: relative;
  font-size: 16px;
  width: 100%;
  max-width: 344px;
  background: #FFFFFF;
  border: 1px solid rgba(25, 25, 38, 0.12);
  border-radius: 12px;
  cursor: pointer;
  outline: none;
  padding: 12px 16px;

  &.error {
    border: 1px solid rgb(238, 80, 80);
  }
}

.selected-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  outline: none;
  width: 100%;
  max-width: 344px;
  padding: 0;
  border: none;
}

.arrow-icon {
  margin-left: 10px;
  transition: transform 0.2s;
}

.arrow-icon.open {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  font-size: 16px;
  z-index: 1;
  border: 1px solid rgba(25, 25, 38, 0.12);
  border-radius: 12px;
}

.options > div {
  padding: 10px;
  cursor: pointer;
}

.options > div:hover {
  background-color: #f2f2f2;
}


@import "_reset";
@import "_typography";
@import "_loader.scss";

body {
  font-family: $fontFamilySatoshi, $fontFamilySansSerif;
  background-color: #EEEFFF;
}

.global__button {
  background: linear-gradient(111.34deg, #F73967 0%, #B92A44 100%);
  box-shadow: 0 2px 8px rgba(7, 8, 10, 0.08), 0 2px 4px rgba(7, 8, 10, 0.04), 0px 16px 32px rgba(7, 8, 10, 0.12);
  border-radius: 12px;
  border: transparent;
  padding: 12px 67px;
  max-width: 200px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  cursor: pointer;

  &.form {
    width: 100%;
    max-width: 100%;
    margin: 8px auto 28px;
    @media(max-width: 744px) {
      &:hover {
        background: linear-gradient(111.34deg, #F73967 0%, #B92A44 100%);;
      }

    }
  }

  &.success, &.contacts {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    @media(max-width: 744px) {
      &:hover {
        background: linear-gradient(111.34deg, #F73967 0%, #B92A44 100%);;
      }
    }
  }


  &:hover {
    background: #F73866;
  }

  &:active {
    background: #B92A44;
  }
}

.cancel__global {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #F73866;
  cursor: pointer;
  border: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  margin: auto;
  margin-bottom: 12px;

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }

  &:active {
    color: rgba(185, 42, 68, 1);
  }
}

.app-preloader {
  @include spinLoader;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #EE5050;
  margin-top: 2px;
  text-align: center;

  &.captcha__contacts {
    text-align: left;
  }

  &.form {
    text-align: left;
  }
}


.wrapper {
  width: 100%;
  max-width: 100%;
  margin: auto;
}

.offers__wrapper {
  background-color: #EEEFFF;
  background-image: url("../images/bg1.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.header {
  max-width: 1312px;
  margin: auto;
  padding: 15px 16px 0 16px;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      margin-right: 16px;

      img {
        &:last-child {
          display: none;
        }
      }
    }
  }

  .navigation {
    display: flex;
    align-items: center;

    .contact__us {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      border: none;
      background: transparent;
      padding: 0;
      cursor: pointer;
      color: #F73866;
      margin-right: 40px;

      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;
      }

      &:active {
        color: rgba(185, 42, 68, 1);
      }

    }

    .log__in {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      max-width: 136px;
      border: 1px solid #FFFFFF;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.12);
      box-shadow: 0 24px 56px rgba(15, 26, 47, 0.06), 0 -16px 56px rgba(15, 26, 47, 0.06);
      backdrop-filter: blur(20px);
      padding: 12px 20px;

      &:hover {
        background: rgba(255, 255, 255, 0.64);
      }

      &:active {
        background: rgba(255, 255, 255, 0.12);
      }


      .icon {
        background-image: url("../images/fluent_person.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 24px;
        height: 24px;
        display: block;
        margin-right: 16px;
      }

      .text {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #F73866;

      }

    }

  }
}

.offers {
  max-width: 1312px;
  margin: auto;
  padding: 0 16px 0 16px;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left__block {
      margin-right: 16px;

      .opportunities__text {
        font-style: normal;
        font-weight: 500;
        font-size: 64px;
        line-height: 76px;
        color: #111212;
        max-width: 632px;
        margin-bottom: 40px;

        > span {
          display: inline-block;
          color: rgba(247, 56, 102, 1);
        }
      }
    }

    .right__block {
      background-image: url("../images/Circle.svg");
      background-position: center;
      background-size: cover;
      display: block;
      width: 632px;
      height: 632px;
      position: relative;
      //z-index: 1;

      .offers__one {
        position: absolute;
        left: calc(50% - 80px / 2 + 1px);
        top: calc(50% - 80px / 2 - 203px);
        width: 80px;
        height: 80px;
        border: 1px solid #FFFFFF;
        background-color: rgba(255, 255, 255, 0.12);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        cursor: pointer;
        padding: 16px;
        display: flex;
        flex-direction: column;


        .headset {
          width: 48px;
          height: 48px;
        }


        > p {
          width: 106px;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          opacity: 0;
        }

        &:hover {
          width: 138px;
          height: 128px;
          background-color: rgba(255, 255, 255, 0.12);
          z-index: 2;
          left: calc(50% - 138px / 2 + 1px);
          top: calc(50% - 128px / 2 - 203px);

          > img {
            margin: 0 auto 8px;
          }

          > p {
            opacity: 1;
          }
        }
      }

      .offers__two {
        position: absolute;
        left: calc(50% - 80px / 2 + 147px);
        top: calc(50% - 80px / 2 - 146px);
        width: 80px;
        height: 80px;
        border: 1px solid #FFFFFF;
        background-color: rgba(255, 255, 255, 0.12);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        cursor: pointer;
        padding: 16px;
        display: flex;
        flex-direction: column;

        > img {
          width: 48px;
          height: 48px;
        }


        > p {
          width: 63px;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          opacity: 0;
        }

        &:hover {
          width: 95px;
          height: 128px;
          background-color: rgba(255, 255, 255, 0.12);
          z-index: 2;
          left: calc(50% - 80px / 2 + 147px);
          top: calc(50% - 80px / 2 - 176px);

          > img {
            margin: 0 auto 8px;
          }

          > p {
            opacity: 1;
          }
        }
      }

      .offers__three {
        position: absolute;
        left: calc(50% - 80px / 2 + 203px);
        top: calc(50% - 80px / 2 - 1px);
        width: 80px;
        height: 80px;
        border: 1px solid #FFFFFF;
        background-color: rgba(255, 255, 255, 0.12);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        cursor: pointer;
        padding: 16px;
        display: flex;
        flex-direction: column;

        > img {
          width: 48px;
          height: 48px;
        }


        > p {
          width: 63px;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          opacity: 0;
        }

        &:hover {
          width: 102px;
          height: 128px;
          background-color: rgba(255, 255, 255, 0.12);
          z-index: 2;
          left: calc(50% - 80px / 2 + 190px);
          top: calc(50% - 128px / 2 - 10px);


          > img {
            margin: 0 auto 8px;
          }

          > p {
            opacity: 1;
          }
        }
      }

      .offers__four {
        position: absolute;
        left: calc(50% - 80px / 2 + 147px);
        top: calc(50% - 80px / 2 + 146px);
        width: 80px;
        height: 80px;
        border: 1px solid #FFFFFF;
        background-color: rgba(255, 255, 255, 0.12);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        cursor: pointer;
        padding: 16px;
        display: flex;
        flex-direction: column;

        > img {
          width: 48px;
          height: 48px;
        }


        > p {
          width: 78px;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          opacity: 0;
        }

        &:hover {
          width: 110px;
          height: 128px;
          background-color: rgba(255, 255, 255, 0.12);
          z-index: 2;
          left: calc(50% - 110px / 2 + 150px);


          > img {
            margin: 0 auto 8px;
          }

          > p {
            opacity: 1;
          }
        }
      }

      .offers__five {
        position: absolute;
        left: calc(50% - 80px / 2);
        top: calc(50% - 80px / 2 + 203px);
        width: 80px;
        height: 80px;
        border: 1px solid #FFFFFF;
        background-color: rgba(255, 255, 255, 0.12);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        cursor: pointer;
        padding: 16px;
        display: flex;
        flex-direction: column;

        > img {
          width: 48px;
          height: 48px;
        }


        > p {
          width: 70px;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          opacity: 0;
        }

        &:hover {
          width: 102px;
          height: 108px;
          background-color: rgba(255, 255, 255, 0.12);
          z-index: 2;
          left: calc(50% - 102px / 2 + 1px);


          > img {
            margin: 0 auto 8px;
          }

          > p {
            opacity: 1;
          }
        }
      }

      .offers__six {
        position: absolute;
        left: calc(50% - 80px / 2 - 147px);
        top: calc(50% - 80px / 2 + 146px);
        width: 80px;
        height: 80px;
        border: 1px solid #FFFFFF;
        background-color: rgba(255, 255, 255, 0.12);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        cursor: pointer;
        padding: 16px;
        display: flex;
        flex-direction: column;

        > img {
          width: 48px;
          height: 48px;
        }


        > p {
          width: 95px;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          opacity: 0;
        }

        &:hover {
          width: 127px;
          height: 108px;
          background-color: rgba(255, 255, 255, 0.12);
          z-index: 2;
          left: calc(50% - 127px / 2 - 160px);


          > img {
            margin: 0 auto 8px;
          }

          > p {
            opacity: 1;
          }
        }
      }

      .offers__seven {
        position: absolute;
        left: calc(50% - 80px / 2 - 203px);
        top: calc(50% - 80px / 2);
        width: 80px;
        height: 80px;
        border: 1px solid #FFFFFF;
        background-color: rgba(255, 255, 255, 0.12);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        cursor: pointer;
        padding: 16px;
        display: flex;
        flex-direction: column;

        > img {
          width: 48px;
          height: 48px;
        }


        > p {
          width: 93px;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          opacity: 0;
        }

        &:hover {
          width: 125px;
          height: 128px;
          background-color: rgba(255, 255, 255, 0.12);
          z-index: 2;
          left: calc(50% - 125px / 2 - 190px);
          top: calc(50% - 128px / 2 - 10px);

          > img {
            margin: 0 auto 8px;
          }

          > p {
            opacity: 1;
          }
        }
      }

      .offers__eight {
        position: absolute;
        left: calc(50% - 80px / 2 - 147px);
        top: calc(50% - 80px / 2 - 146px);
        width: 80px;
        height: 80px;
        border: 1px solid #FFFFFF;
        background-color: rgba(255, 255, 255, 0.12);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        cursor: pointer;
        padding: 16px;
        display: flex;
        flex-direction: column;

        > img {
          width: 48px;
          height: 48px;
        }


        > p {
          width: 116px;
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          opacity: 0;
        }

        &:hover {
          width: 148px;
          height: 128px;
          background-color: rgba(255, 255, 255, 0.12);
          z-index: 2;
          left: calc(50% - 148px / 2 - 147px);
          top: calc(50% - 128px / 2 - 150px);

          > img {
            margin: 0 auto 8px;
          }

          > p {
            opacity: 1;
          }
        }
      }
    }
  }

}

.benefits {
  background: #EEEFFF url("../images/bg2.png") center/cover no-repeat;
  width: 100%;
  height: 100%;

  &__inner {
    width: 100%;
    max-width: 1280px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right__block {
      margin-right: 70px;
    }

    .right__block, .left__block {
      width: 50%;

      .benefits__item {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
        max-width: 616px;
        padding: 16px;
        margin-bottom: 34px;

        &:nth-child(4),
        &:nth-child(8) {
          margin-bottom: 0;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.12);
          box-shadow: 0 24px 56px rgba(15, 26, 47, 0.06), 0 -16px 56px rgba(15, 26, 47, 0.06);
          backdrop-filter: blur(20px);
          border-radius: 24px;

          .text__wrap {
            .top {
              > h4 {
                color: rgba(247, 56, 102, 1)
              }
            }
          }
        }

        .image {
          width: 80px;
          height: 80px;
          background-color: rgba(255, 255, 255, 0.12);
          border: 1px solid #FFFFFF;
          box-shadow: 0 24px 56px rgba(15, 26, 47, 0.06), 0px -16px 56px rgba(15, 26, 47, 0.06);
          backdrop-filter: blur(20px);
          border-radius: 12px;
          margin-right: 24px;
          padding: 16px;

          .icon {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 48px;
            height: 48px;
            width: 48px;
            display: block;

            &.wallet-icon {
              background-image: url("../images/fluent_wallet-credit-card.svg");
            }

            &.window-icon {
              background-image: url("../images/fluent_window-apps.svg");
            }

            &.headset-icon {
              background-image: url("../images/fluent_headset.svg");
            }

            &.chart-icon {
              background-image: url("../images/fluent_chart-person.svg");
            }

            &.wand-icon {
              background-image: url("../images/fluent_wand.svg");
            }

            &.star-icon {
              background-image: url("../images/fluent_person-star.svg");
            }

            &.timer-icon {
              background-image: url("../images/fluent_timer.svg");
            }

            &.people-icon {
              background-image: url("../images/fluent_people.svg");
            }
          }


        }

        .text__wrap {
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > h4 {
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 32px;
              color: #111212;
              margin-bottom: 4px;
            }

            .arrow {
              background-image: url("../images/arrow.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: 24px;
              width: 24px;
              height: 24px;
              display: none;
              transform: rotate(0);
              transition: all 0.2s ease-in-out;

              &.rotate {
                transform: rotate(180deg);
              }
            }


          }

          .bottom {
            > p {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #353538;
            }
          }
        }

      }
    }
  }
}

.partnership {
  width: 100%;
  max-width: 1280px;
  margin: auto;

  &__inner {
    background-image: url("../images/circles.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    max-width: 738px;
    margin: auto;
    padding: 20px 0 40px;

    > h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 64px;
      line-height: 76px;
      color: #111212;
      margin: 0 auto 16px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #5F5F62;
      text-align: center;
      margin-bottom: 40px;
    }

    .buttons__wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      .global__button {
        margin-right: 32px;
        padding: 12px 54px;
      }

      .contact__us {
        cursor: pointer;
        max-width: 142px;
        border: 1px solid #FFFFFF;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.12);
        box-shadow: 0 24px 56px rgba(15, 26, 47, 0.06), 0 -16px 56px rgba(15, 26, 47, 0.06);
        backdrop-filter: blur(20px);
        padding: 12px 23px;
        color: #F73866;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;

        &:hover {
          background: rgba(255, 255, 255, 0.64);
        }

        &:active {
          background: rgba(255, 255, 255, 0.12);
        }
      }

    }
  }
}

.footer {
  width: 100%;
  max-width: 1280px;
  margin: auto;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 16px 40px;

    .left {
      display: flex;
      align-items: center;

      > span {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #353538;
        margin-right: 12px;
      }

      .messenger__wrap {
        display: flex;
        align-items: center;

        .telegram, .skype {
          width: 40px;
          height: 40px;
          background: rgba(255, 255, 255, 0.12);
          border: 1px solid #FFFFFF;
          box-shadow: 0 24px 56px rgba(15, 26, 47, 0.06), 0 -16px 56px rgba(15, 26, 47, 0.06);
          backdrop-filter: blur(20px);
          border-radius: 12px;
          padding: 8px;
          margin-right: 12px;

          &:hover {
            background: rgba(255, 255, 255, 0.64);
          }

          &:active {
            background: rgba(255, 255, 255, 0.12);
          }
        }
      }
    }

    .right {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #353538;
    }
  }

}


//FORMS
.form__wrap {
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &.registration__form {
    display: none;
  }

  &.login__form {
    display: none;
  }

  &.contacts__form {
    display: none;
  }
}

.background__modal {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.form__inner {
  background: #F3F3F3;
  border: 1px solid rgba(255, 255, 255, 0.92);
  box-shadow: 0 24px 56px rgba(15, 26, 47, 0.06), 0 -16px 56px rgba(15, 26, 47, 0.06);
  backdrop-filter: blur(20px);
  border-radius: 16px;
  width: 100%;
  height: auto;
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);

  &.register {
    max-width: 760px;

    .g-recaptcha {
      width: 330px !important;
      transform: scale(1.1);
      transform-origin: 0 0;
      margin: 8px auto 16px;

      .error-text {
        text-align: left;
      }
    }
  }

  &.success {
    max-width: 380px;
  }

  &.login {
    max-width: 380px;

    .dont__account {
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;

      .sign__up {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        border: none;
        background: transparent;
        padding: 0;
        cursor: pointer;
        color: #F73866;
        margin-left: 6px;

        &:hover {
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 6px;
        }

        &:active {
          color: rgb(185, 42, 68);
        }
      }
    }
  }

  &.contacts {
    max-width: 380px;

    .g-recaptcha {
      margin: 24px auto 24px;
      width: 330px !important;
      transform: scale(1.1);
      transform-origin: 0 0;
    }
  }

  .react__form {
    padding: 24px;

    .success__modal-wrap {
      .close__wrap {
        display: flex;
        justify-content: flex-end;

        > img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }
  }

  .form__header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.92);
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #353538;
    }

    > img {
      cursor: pointer;
      width: 24px;
      height: 24px;

    }

    &.success {
      display: none;
    }

  }

  .register__wrap {
    display: flex;
    justify-content: space-between;

    .left {
      margin-right: 24px;

    }

    .left, .right {
      width: 100%;
      max-width: 344px;
    }
  }

  .success__modal-wrap {
    .icon__wrap {
      background: rgba(255, 255, 255, 0.12);
      border: 1px solid #FFFFFF;
      box-shadow: 0 24px 56px rgba(15, 26, 47, 0.06), 0 -16px 56px rgba(15, 26, 47, 0.06);
      backdrop-filter: blur(20px);
      border-radius: 12px;
      width: 80px;
      height: 80px;
      padding: 16px;
      margin: 0 auto 24px;
    }

    .title {
      display: block;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #353538;
      margin-bottom: 8px;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #353538;
      margin-bottom: 24px;
    }
  }

}

@import "_media.scss";













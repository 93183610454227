@media(max-width: 2560px) {
  @media(max-height: 1600px) {
    .registration__form {
      .background__modal {
        min-height: 850px;
        position: absolute;
        padding: 0 16px;
      }
    }
  }
}

@media (max-width: 1099px) {
  .offers {
    padding: 0 40px 0 40px;

    &__top {
      justify-content: center;
      align-items: center;
      height: 500px;
      background-image: url("../images/circles-tablet.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 500px;

      .left__block {
        margin-right: 0;

        .opportunities__text {
          text-align: center;
          width: 100%;
          max-width: 664px;
          margin: 0 auto 40px;
        }

        .global__button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
        }
      }

      .right__block {
        display: none;
      }
    }
  }
  .benefits {
    &__inner {
      align-items: baseline;

      .right__block, .left__block {
        .benefits__item {
          cursor: unset;

          &:hover {
            background: none;
            box-shadow: none;
            backdrop-filter: none;
            border-radius: unset;

            .text__wrap {
              max-width: 100%;

              .top {
                > h4 {
                  color: #111212;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 744px) {
  .header {
    .navigation {
      .contact__us {
        &:hover {
          text-decoration: unset;
        }

        &:active {
          color: #F73866;
        }
      }

      .log__in {
        &:hover {
          background: rgba(255, 255, 255, 0.12);
        }
      }
    }
  }

  .registration__form {
    .background__modal {
      min-height: 1440px;
      position: absolute;
      padding: 0 16px;

      &.fixed {
        min-height: 400px;
      }
    }

  }
  .contacts__form {
    .background__modal {
      min-height: 700px;
      position: absolute;
      padding: 0 16px;

      &.fixed {
        min-height: 400px;
      }
    }
  }
  .login__form {
    .background__modal {
      min-height: 400px;
      position: absolute;
      padding: 0 16px;
    }
  }
  .form__inner {
    &.register {
      max-width: 320px;
      top: 48%;

      .cancel__global {
        &:hover {
          text-decoration: unset;
        }

        &:active {
          color: #F73866;;
        }
      }

      .g-recaptcha {
        width: 330px !important;
        transform: scale(.9);
        transform-origin: 0 0;
        margin: 24px auto 16px;
      }
    }

    &.contacts {
      .g-recaptcha {
        width: 330px !important;
        transform: scale(.9);
        transform-origin: 0 0;
        margin: 24px auto 16px;
      }

    }

    .register__wrap {
      flex-wrap: wrap;

      .left, .right {
        margin-right: 0;
        max-width: 100%;

        .input__wrapper {
          max-width: 100% !important;

          .select {
            max-width: 100%;
          }

          .input__global {
            max-width: 100%;
          }

          .container__phone {
            max-width: 100% !important;

            .input__phone {
              max-width: 100% !important;
            }

            .dropdown__phone {
              min-width: 270px !important;
            }
          }

        }

        .textarea__wrapper {
          max-width: 100% !important;

          .textarea {
            max-width: 100% !important;
            height: 48px !important;
          }
        }
      }


    }
  }
  .offers {
    .partners__wrap {
      .partners__links {
        .link {
          > img {
            &:hover {
              transform: scale(1);
            }
          }

        }

      }

    }

    .global__button {
      &:hover {
        background: linear-gradient(111.34deg, #F73967 0%, #B92A44 100%);
      }
    }
  }
  .partnership {
    .global__button {
      &:hover {
        background: linear-gradient(111.34deg, #F73967 0%, #B92A44 100%);
      }
    }

    .buttons__wrap {
      .contact__us {
        &:hover {
          background: rgba(255, 255, 255, 0.12);
        }
      }
    }

  }
  .footer {
    &__inner {
      .left {
        .messenger__wrap {

          .telegram, .skype {
            &:hover {
              background: rgba(255, 255, 255, 0.12);
            }
          }
        }
      }

    }

  }
}

@media(max-width: 700px) {
  .header {
    &__inner {
      .navigation {
        .log__in {
          padding: 11px;

          .icon {
            margin-right: 0;
          }

          .text {
            display: none;
          }

        }

      }

    }

  }
  .offers {
    padding: 0 16px 0 16px;

    .partners__wrap {
      max-width: 100%;

      .partners__links {
        flex-direction: column;

        .link {
          margin-right: 0 !important;
          margin-bottom: 16px;

          &:nth-child(1) {
            order: 2;
          }

          &:nth-child(3) {
            order: -1;
          }
        }
      }
    }
  }
  .benefits {
    &__inner {
      flex-direction: column;
      justify-content: center;
      padding-top: 0;

      .right__block, .left__block {
        width: 100%;
        margin-right: 0;
      }
    }

    &__item {
      margin: 0 auto 26px !important;

      .text__wrap {
        max-width: 100% !important;
      }
    }

  }
  .footer {
    &__inner {
      flex-direction: column;

      .left {
        flex-direction: column;

        > span {
          margin: 0 0 16px 0;
        }
      }

      .right {
        text-align: center;
      }

      .messenger__wrap {
        margin-bottom: 16px;

        .telegram, .skype {
          width: 48px !important;
          height: 48px !important;
          padding: 12px !important;

        }
      }
    }

  }
}

@media (max-width: 430px) {
  .header {
    &__inner {
      .logo {
        margin-right: 0;

        img {
          &:first-child {
            display: none;
          }
          &:last-child {
            display: block;
          }
        }

        //> img {
        //  width: 148px;
        //  height: 48px;
        //  margin-left: -10px;
        //
        //}

      }
    }
  }
  .offers {
    &__top {
      background-size: 320px;
      height: 320px;
      margin-bottom: 24px;

      .left__block {
        .opportunities__text {
          font-style: normal;
          font-weight: 500;
          font-size: 36px;
          line-height: 44px;
          max-width: 288px;
          margin: 0 auto 32px;
        }

      }
    }

    .partners__wrap {
      padding: 16px;
    }
  }
  .benefits {
    &__inner {
      .right__block, .left__block {
        .benefits__item {
          padding: 8px 24px;
          align-items: inherit;


          .image {
            width: 52px;
            height: 52px;
            padding: 0;
            margin-right: 16px;

            .icon {
              background-size: 32px;
            }
          }

          .text__wrap {
            width: 100%;
            position: relative;

            .top {
              justify-content: space-between;
              margin-top: 12px;

              > h4 {
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
              }

              .arrow {
                display: flex;
                margin-right: 4px;
              }
            }
          }

          .bottom {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s ease-in-out;


            &.active {
              max-height: 1000px;
              transition: max-height 0.5s ease-in-out;

            }
          }

        }

      }

    }

  }
  .partnership {
    height: 320px;

    &__inner {
      background-size: 320px;
      background-position: top;

      > h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        max-width: 288px;
        padding-top: 24px;
      }

      .buttons__wrap {
        flex-direction: column;

        .global__button {
          margin-right: 0;
          margin-bottom: 16px;
        }

        .contact__us {
          max-width: 200px;
          width: 100%;
        }
      }

    }

  }
}

@media(max-width: 350px) {
  .global__button {
    &.form {
      padding: 12px 60px;
    }
  }
  .header {
    &__inner {
      .navigation {
        .contact__us {
          margin-right: 8px;
        }


      }

    }

  }
  .form__inner {
    &.register {
      .g-recaptcha {
        width: 330px !important;
        transform: scale(.8);
        transform-origin: 0 0;
        margin: 24px auto 16px;
      }
    }

    &.contacts {
      .g-recaptcha {
        width: 330px !important;
        transform: scale(.8);
        transform-origin: 0 0;
        margin: 24px auto 16px;
      }

    }

    &.login {
      .dont__account {
        font-size: 16px;

        .sign__up {
          font-size: 16px;
        }

      }
    }

  }
}

@media(max-height: 430px) {
  .registration__form {
    .background__modal {
      min-height: 850px;
      position: absolute;
      padding: 0 16px;
    }
  }
  .login__form {
    .background__modal {
      min-height: 550px;
      position: absolute;
      padding: 0 16px;
    }
  }
}

@media(max-height: 415px) {
  .registration__form {
    .background__modal {
      min-height: 1250px;
      position: absolute;
      padding: 0 16px;
    }
  }
}

//@media (min-width: 320px) and (max-width: 480px) {
//  #recaptcha_challenge_image {
//    margin: 0 !important;
//    width: 100% !important;
//  }
//  #recaptcha_response_field {
//    margin: 0 !important;
//    width: 100% !important;
//  }
//  #recaptcha_image {
//    margin: 0 !important;
//    width: 100% !important;
//  }
//  .recaptcha_r1_c1,
//  .recaptcha_r3_c1,
//  .recaptcha_r3_c2,
//  .recaptcha_r7_c1,
//  .recaptcha_r8_c1,
//  .recaptcha_r3_c3,
//  .recaptcha_r2_c1,
//  .recaptcha_r4_c1,
//  .recaptcha_r4_c2,
//  .recaptcha_r4_c4,
//  .recaptcha_image_cell {
//
//    margin: 0 !important;
//    width: 100% !important;
//    background: none !important;
//  }
//
//}

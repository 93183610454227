@import "./src/css/typography";

.container__phone {
  width: 100% !important;
  max-width: 344px !important;
  font-family: $fontFamilySatoshi, $fontFamilySansSerif;
}

.dropdown__phone {
  margin: 4px 0 4px -1px !important;
  box-shadow: 0 2px 8px rgba(7, 8, 10, 0.06), 0 2px 4px rgba(7, 8, 10, 0.04), 0 8px 32px rgba(7, 8, 10, 0.06) !important;
  border-radius: 12px !important;
  background: #FFFFFF !important;
  border: 1px solid rgba(25, 25, 38, 0.12) !important;
  max-width: 100% !important;
  min-width: 344px !important;

}

.input__phone {
  width: 100% !important;
  max-width: 344px !important;
  height: 48px !important;
  background: #FFFFFF !important;
  border: 1px solid rgba(25, 25, 38, 0.12) !important;
  border-radius: 12px !important;
  cursor: pointer !important;

  &.error {
    border: 1px solid rgb(238, 80, 80) !important;
    background: transparent !important;
  }

  //&:hover {
  //  border: 2px solid rgb(15, 18, 20) !important;
  //
  //}
  //
  //&:focus {
  //  border: 1px solid rgb(15, 18, 20) !important;
  //  box-shadow: 0 0 0 2.8px rgba(20, 20, 43, 0.12) !important;
  //
  //
  //}
  //
  //&:active {
  //  border: 1px solid rgba(25, 25, 38, 0.12) !important;
  //
  //}

}

.dropdown__button {
  border-radius: 12px 0 0 12px !important;
  border: 1px solid rgba(25, 25, 38, 0.12) !important;

  &.error {
    border: 1px solid rgb(238, 80, 80) !important;
    background: transparent !important;
  }


  .selected-flag {
    border-radius: 12px 0 0 12px !important;

    &:hover {
      border-radius: 12px 0 0 12px !important;
    }

    &:active {
      border-radius: 12px 0 0 12px !important;
    }
  }

  .selected-flag.open {
    border-radius: 12px 0 0 12px !important;
  }


}

.textarea__wrapper {
  max-width: 344px !important;


  .textarea {
    align-items: center;
    padding: 11px 16px;
    gap: 8px;
    width: 100%;
    max-width: 344px !important;
    height: 140px !important;
    background: #FFFFFF;
    border: 1px solid rgba(25, 25, 38, 0.12);
    border-radius: 12px;
    cursor: pointer;
    outline: none;
    resize: none;


    &::placeholder {
      $fontFamilySatoshi: 'Satoshi';
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #5F5F62;
    }


    //&:hover {
    //  border: 2px solid rgb(15, 18, 20);
    //  padding: 11px 15px;
    //}
    //
    //&:focus {
    //  border: 1px solid rgb(15, 18, 20);
    //  box-shadow: 0 0 0 2.8px rgba(20, 20, 43, 0.12);
    //  padding: 12px 16px;
    //
    //}
    //
    //&:active {
    //  border: 1px solid rgba(25, 25, 38, 0.12);
    //  padding: 12px 16px;
    //}

    &.error {
      border: 1px solid #EE5050;
      text-align: left;
    }

  }

  .error-text {
    text-align: left;
  }
}



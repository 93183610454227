.label__global {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #353538;
  display: block;
  margin-bottom: 4px;

  > i {
    color: rgba(238, 80, 80, 1);
  }
}
